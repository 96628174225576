<template>
  <div class="row">
    <div class="col-12">
      <h4>AREAS</h4>
      <div class="card table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Estado</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>{{ item.name }}</td>
              <td>
                <app-badge-status :value="item.enable"></app-badge-status>
              </td>
              <td class="text-right">
                <div class="btn-group">
                  <button
                    class="btn btn-sm btn-light"
                    @click="
                      $refs.dialogFormArea.show();
                      $refs.formArea.loadReg(item);
                    "
                  >
                    <i class="fa fa-edit"></i>
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <button
                  class="btn btn-primary"
                  @click="
                    $refs.dialogFormArea.show();
                    $refs.formArea.reset();
                  "
                >
                  Nuevo
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <app-modal-basic ref="dialogFormArea">
      <form-area
        ref="formArea"
        @submitted="
          $store.dispatch('admin/getAreas', true);
          $refs.dialogFormArea.hide();
          getAreas();
        "
      ></form-area>
    </app-modal-basic>
  </div>
</template>

<script>
import FormArea from "./Form.vue";
import { AdminService } from "../AdminService";

export default {
  components: {
    FormArea
  },
  data() {
    return {
      items: [],
      area_id: 5
    };
  },
  created() {
    this.getAreas();
  },
  methods: {
    getAreas() {
      AdminService.getAreas({}, true).then((list) => (this.items = list));
    }
  }
};
</script>

<style></style>
